<template>
  <div id="app">
    <nav-bar v-if="$route.meta.showLoggedNavBar"></nav-bar>
    <router-view v-if="isRouterAlive" />
    <Footer v-if="$route.meta.showFooter"></Footer>
  </div>
</template>

<script>
import NavBar from "./components/Navbar";
import Footer from "./components/Footer";
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  components: {
    NavBar,
    Footer,
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        let token = this.$storage.get("token");
        if (route.meta.requireLogin) {
          if (!token) {
            this.$message.error({
              message: "请登录",
            });
            this.$router.replace("/home");
          }
        }
      },
      immediate: true,
    },
  },
};
</script>


