<template>
  <div class="navbar">
    <div class="content">
      <img src="../assets/img/logo.png" alt="" @click="$router.replace('/homeLogged')" style="cursor:pointer"/>
      <div class="navbar-right">
        <div
          :class="
            $route.name == item.name ? 'navbar-item active' : 'navbar-item'
          "
          @click="clickNavBar(item.id)"
          v-for="(item, index) in navbar"
          :key="index"
        >
          <a href="javascript:;" @click="tolink(item.url,item.text)">{{ item.text }}</a>
          <img :src="item.img" alt="" v-if="item.img" />
        </div>
        <div
          :class="
            $route.path.indexOf('vipCenter') !== -1
              ? 'navbar-item active'
              : 'navbar-item'
          "
          @click.stop="isShow = !isShow"
        >
          <a href="javascript:;">会员中心</a>
          <img src="../assets/img/xiala.png" alt="" />
          <div class="vip" v-show="isShow">
            <router-link to="/vipCenter">个人中心</router-link>
            <a href="javascript:;" @click="exit">退出登录</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      isShow: false,
      currentIndex: 1,
      navbar: [
        {
          id: 1,
          text: "首页",
          url: "/homeLogged",
          name: "HomeLogged",
        },
        { id: 2, text: "0门槛入伙", url: "/joinRaise", name: "Join" },
        {
          id: 3,
          text: "VIP定制",
          url: "/customized",
          name: "Customized",
        },
        {
          id: 4,
          text: "发起合伙定制",
          url: "/initiate",
          name: "Initiate",
        },
        {
          id: 5,
          text: "快速入门",
          url: "/getStart",
          name: "GetStart",
        },
        {
          id: 6,
          text: "积分商城",
          url: "/pointShop",
          name: "PointShop",
        },
        { id: 7, text: "关于我们", url: "/aboutUs", name: "AboutUs" },
        { id: 8, text: "下载APP", url: "/download", name: "Download" },
        { id: 9, text: "帮助中心", url: "/help", name: "Help" },
      ],
    };
  },

  components: {},

  methods: {
    clickNavBar(index) {
      this.currentIndex = index;
    },
    exit() {
      this.$storage.remove("token");
      this.$router.replace("/home");
    },
    tolink(link,text){
      this.$router.push(link);
    }
  },

  computed: {
    activeBar() {
      return window.localStorage.getItem("activeBar");
    },
  },
};
</script>
<style lang="less" scoped>
.active {
  position: relative;
  a {
    color: #1578ff !important;
  }
}
.active::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -6px;
  display: inline-block;
  width: 83%;
  height: 4px;
  background: #1578ff;
  border-radius: 2px;
}
.navbar {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.content {
  width: 1315px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 128px;
  }
  .navbar-right {
    display: flex;
    .navbar-item {
      position: relative;
      cursor: pointer;
      margin: 0 20px;
      a {
        white-space: nowrap;
        font-size: 18px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
      }
      img {
        vertical-align: middle;
        width: 16px;
        height: 16px;
      }
    }
    .vip {
      z-index: 9;
      position: absolute;
      width: 100%;
      background: #fff;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      a {
        padding: 5px 0;
        width: 100%;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        font-size: 16px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
      }
    }
    .navbar-item:nth-last-child(1) {
      margin-right: 0;
    }
  }
}
</style>
