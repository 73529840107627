import Vue from 'vue'
import VueRouter from 'vue-router'
// 登陆 
const Login = () => import('../views/Login.vue');
// 忘记密码
const Forget = () => import('../views/Forget.vue');
// 注册
const Register = () => import('../views/Register.vue');
//首页
const Home = () => import('../views/Home.vue');
const HomeLogged = () => import('../views/HomeLogged.vue')
//用户协议
const UserAgreements = () => import('../views/agreements/UserAgreements.vue')
//隐私协议
const PrivacyAgreement = () => import('../views/agreements/PrivacyAgreement.vue')
//服务协议
const ServiceAgreement = () => import('../views/agreements/ServiceAgreement.vue')
//关于我们
const AboutUs = () => import('../views/AboutUs.vue')
//公告详情
const NoticeDetail = () => import('../views/NoticeDetail.vue')
//全部公告
const AllNotice = () => import('../views/AllNotice.vue')
//帮助
const Help = () => import('../views/Help.vue')
//发起合伙定制
const Initiate = () => import('../views/Initiate.vue')
//下载
const Download = () => import('../views/Download.vue')
//积分商城
const PointShop = () => import('../views/PointShop.vue')
//积分商城
const GetStart = () => import('../views/GetStart.vue')
// 入伙 募集中产品
const Join = () => import('../views/join/Join.vue');
// 入伙2 操作中产品
const Join2 = () => import('../views/join/Join2.vue');
// 入伙3 已清盘产品
const Join3 = () => import('../views/join/Join3.vue');
// 入伙详情 募集中
const Collection = () => import('../views/join/details/Collection.vue');
// 定制 列表
const Customized = () => import('../views/customized/Customized.vue');
// 定制详情 已清盘
const Customwinding = () => import('../views/customized/Customwinding.vue');
//会员中心
const VipCenter = () => import('../views/VIPCenter/VIPCenter.vue')
//我的
const Profile = () => import('../views/VIPCenter/ChildComps/Profile.vue')
//修改手机号
const ChangePhone = () => import('../views/VIPCenter/ChildComps/ChangePhone.vue')
//修改资金密码
const ChangeMoneyPwd = () => import('../views/VIPCenter/ChildComps/ChangeMoneyPwd.vue')
// 个人中心  我的入伙
const Myjoin = () => import('../views/VIPCenter/ChildComps/join/Myjoin.vue');
// 个人中心  我的定制
const Mycustom = () => import('../views/VIPCenter/ChildComps/custom/Mycustom.vue');
// 个人中心  充值
const Recharge = () => import('../views/VIPCenter/ChildComps/recharge/Recharge.vue');
// 个人中心  充值
const Recharge2 = () => import('../views/VIPCenter/ChildComps/recharge/Recharge2.vue');
//充值记录
const RechargeRecord = () => import('../views/VIPCenter/ChildComps/recharge/RechargeRecord.vue');
// 个人中心  取款
const Withdraw = () => import('../views/VIPCenter/ChildComps/withdraw/Withdraw.vue');
//提现记录
const WithdrawDetailed = () => import('../views/VIPCenter/ChildComps/withdraw/WithdrawDetailed.vue');
// 个人中心  资金明细
const Detailed = () => import('../views/VIPCenter/ChildComps/detailed/Detailed.vue');
// 个人中心  邀请好友
const Friends = () => import('../views/VIPCenter/ChildComps/friends/Friends.vue');
const FriendsList = () => import('../views/VIPCenter/ChildComps/friends/FriendsList.vue');
// 个人中心  站内信
const Station = () => import('../views/VIPCenter/ChildComps/station/Station.vue');
// 个人中心  实名认证
const RealNameVerification = () => import('../views/VIPCenter/ChildComps/RealNameVerification.vue');

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      showLoggedNavBar: false,
      showFooter: false
    },
    // beforeEnter: (to, from, next) => {
    //   if (to.name == 'Home' && window.localStorage.getItem('token')) {
    //     next({ path: '/homeLogged' });
    //     return false;
    //   }
    //   next();
    // }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      showLoggedNavBar: false,
      showFooter: false
    }
  },
  {
    path: '/forget',
    name: 'Forget',
    component: Forget,
    meta: {
      showLoggedNavBar: false,
      showFooter: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      showLoggedNavBar: false,
      showFooter: false
    }
  },
  //登录后主页
  {
    path: '/homeLogged',
    name: 'HomeLogged',
    component: HomeLogged,
    meta: {
      showLoggedNavBar: true,
      showFooter: false,
      requireLogin: true
    }
  },
  //用户协议
  {
    path: '/userAgreements',
    name: 'UserAgreements',
    component: UserAgreements,
    meta: {
      showLoggedNavBar: false,
      showFooter: true
    }
  },
  //隐私协议
  {
    path: '/privacyAgreement',
    name: 'PrivacyAgreement',
    component: PrivacyAgreement,
    meta: {
      showLoggedNavBar: false,
      showFooter: true
    }
  },
  //服务协议
  {
    path: '/serviceAgreement',
    name: 'ServiceAgreement',
    component: ServiceAgreement,
    meta: {
      showLoggedNavBar: false,
      showFooter: true
    }
  },

  //公告详情
  {
    path: '/noticeDetail',
    name: 'NoticeDetail',
    component: NoticeDetail,
    meta: {
      showLoggedNavBar: false,
      showFooter: true,
      requireLogin: false
    }
  },
  //全部公告
  {
    path: '/allNotice',
    name: 'AllNotice',
    component: AllNotice,
    meta: {
      showLoggedNavBar: false,
      showFooter: true,
      requireLogin: false
    }
  },
  //积分商城
  {
    path: '/pointShop',
    name: 'PointShop',
    component: PointShop,
    meta: {
      showLoggedNavBar: true,
      showFooter: true,
      requireLogin: true
    }
  },
  //快速入门
  {
    path: '/getStart',
    name: 'GetStart',
    component: GetStart,
    meta: {
      showLoggedNavBar: true,
      showFooter: true,
      requireLogin: true
    }
  },

  //发起合伙定制
  {
    path: '/initiate',
    name: 'Initiate',
    component: Initiate,
    meta: {
      showLoggedNavBar: true,
      showFooter: true,
      requireLogin: true
    }
  },
  //关于我们
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: AboutUs,
    meta: {
      showLoggedNavBar: false,
      showFooter: true
    }
  },
  //帮助
  {
    path: '/help',
    name: 'Help',
    component: Help,
    meta: {
      showLoggedNavBar: true,
      showFooter: true,
      requireLogin: true
    }
  },
  //下载
  {
    path: '/download',
    name: 'Download',
    component: Download,
    meta: {
      showLoggedNavBar: true,
      showFooter: true
    }
  },
  {
    path: '/joinRaise',
    name: 'Join',
    component: Join,
    meta: {
      showLoggedNavBar: true,
      showFooter: true,
      requireLogin: true
    }
  },
  {
    path: '/joinOperation',
    name: 'Join',
    component: Join2,
    meta: {
      showLoggedNavBar: true,
      showFooter: true,
      requireLogin: true
    }
  },
  {
    path: '/joinWinding',
    name: 'Join',
    component: Join3,
    meta: {
      showLoggedNavBar: true,
      showFooter: true,
      requireLogin: true
    }
  },
  {
    path: '/collection',
    name: 'Collection',
    component: Collection,
    meta: {
      showLoggedNavBar: true,
      showFooter: true,
      requireLogin: true
    }
  },
  {
    path: '/customized',
    name: 'Customized',
    component: Customized,
    meta: {
      showLoggedNavBar: true,
      showFooter: true,
      requireLogin: true
    }
  },
  {
    path: '/customwinding',
    name: 'Customwinding',
    component: Customwinding,
    meta: {
      showLoggedNavBar: true,
      showFooter: true,

    }
  },
  //会员中心
  {
    path: '/vipCenter',
    name: "VipCenter",
    component: VipCenter,
    redirect: '/vipCenter/profile',
    children: [
      {
        path: '/vipCenter/profile',
        component: Profile,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        },
      },
      //修改手机号
      {
        path: '/vipCenter/changePhone',
        component: ChangePhone,
        name: "ChangePhone",
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        },
      },
      //修改资金密码
      {
        path: '/vipCenter/changeMoneyPwd',
        component: ChangeMoneyPwd,
        name: "ChangeMoneyPwd",
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        },
      },
      //实名认证
      {
        path: '/vipCenter/realNameVerification',
        name: 'RealNameVerification',
        component: RealNameVerification,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        }
      },
      {
        path: '/vipCenter/myJoin',
        name: 'Myjoin',
        component: Myjoin,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        }
      },
      {
        path: '/vipCenter/myCustom',
        name: 'Mycustom',
        component: Mycustom,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        }
      },
      {
        path: '/vipCenter/recharge',
        name: 'Recharge',
        component: Recharge,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        }
      },
      {
        path: '/vipCenter/rechargeForm',
        name: 'Recharge2',
        component: Recharge2,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        }
      },
      //充值记录
      {
        path: '/vipCenter/rechargeRecord',
        name: 'RechargeRecord',
        component: RechargeRecord,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        }
      },
      {
        path: '/vipCenter/withdraw',
        name: 'Withdraw',
        component: Withdraw,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        }
      },
      //提现记录
      {
        path: '/vipCenter/withDrawDetailed',
        name: 'WithdrawDetailed',
        component: WithdrawDetailed,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        }
      },
      //资金明细
      {
        path: '/vipCenter/detailed',
        name: 'Detailed',
        component: Detailed,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        }
      },
      {
        path: '/vipCenter/friends',
        name: 'Friends',
        component: Friends,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        }
      },
      {
        path: '/vipCenter/friendsList',
        name: 'FriendsList',
        component: FriendsList,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        }
      },
      {
        path: '/vipCenter/station',
        name: 'Station',
        component: Station,
        meta: {
          showLoggedNavBar: true,
          showFooter: false,
          requireLogin: true
        }
      },
    ]
  }
]

const router = new VueRouter({
  routes
})
///router为路由对象，添加beforeEach导航守卫
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
})

export default router
