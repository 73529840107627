import Vue from "vue";
import {
    CountDown,
    Circle,
    Uploader,
    Progress,
    Button
} from 'vant'

Vue.use(CountDown)
Vue.use(Circle);
Vue.use(Uploader);
Vue.use(Progress);
Vue.use(Button);