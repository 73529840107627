import Vue from 'vue'
import {
    Carousel, 
    CarouselItem,
    Progress,
    Breadcrumb,
    BreadcrumbItem,
    Upload,
    Select,
    Option,
    Cascader,
    Pagination,
    Button,
    Checkbox,
    Form,
    FormItem,
    Input,
    Tabs,
    TabPane,
    Table,
    TableColumn,
    DatePicker,
    Notification,
    Message,
    InfiniteScroll,
    Dialog,
    Empty,
    Badge
} from 'element-ui'

Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Progress);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Upload);
Vue.use(Select);
Vue.use(Option);
Vue.use(Cascader);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(TabPane);
Vue.use(Tabs);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(InfiniteScroll);
Vue.use(Dialog);
Vue.use(Empty);
Vue.use(Badge);
Vue.prototype.$notify=Notification
Vue.prototype.$message=Message //消息提示