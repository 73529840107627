import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.css'
import './plugin/element'
import qr from 'vue-qr'
import VueLazyLoad from 'vue-lazyload'
import '@vant/touch-emulator'
import './plugin/vant'
import moment from 'moment'; //导入模块
import storage from './utils/storage'
import VueClipboard from 'vue-clipboard2'
import Meta from 'vue-meta'

Vue.config.productionTip = false

Vue.prototype.$storage = storage;

Vue.use(VueClipboard)

Vue.use(qr);
//图片懒加载
Vue.use(VueLazyLoad,{
  loading:require('./assets/img/banner.png')
});

Vue.use(Meta);

moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn');
//定义全局过滤器实现日期格式化
Vue.filter("format", function(input, fmtstring) {
  // 使用momentjs这个日期格式化类库实现日期的格式化功能
  return moment(input).format(fmtstring);
});

Vue.prototype.$moment = moment;//赋值使用

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
