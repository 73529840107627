import axios from "axios";
import storage from '../utils/storage'
import { BASE_URL } from '../constants/constants'
//导入网页加载进度条NProgress
import NProgress from 'nprogress'

export function request(config) {
    const instance = axios.create({
        baseURL: BASE_URL,
        timeout: 10000
    })
    instance.interceptors.request.use(config => {
        let token = storage.get("token");
        if (token) {
            // config.headers.common['Authorization'] = token;
            config.headers.common['token'] = token;
        }
        config.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
        //展示进度条
        NProgress.start();
        // config.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        // console.log(config);
        return config
    }, error => {
        return Promise.reject(error)
    });

    instance.interceptors.response.use(config => {
        // console.log(config);
        //隐藏进度条
        NProgress.done();
        return config.data;

    }, error => {
        // 请求错误时做些事
        let status = ''
        if (error.request) {
            status = error.request
        } else if (error.response) {
            status = error.response
        }

        if (status) {
            switch (status.status) {
                case 400: error.message = '请求错误(400)';
                    error.response = JSON.parse(status.response)
                    break;
                case 401: error.message = '未授权，请重新登录(401)';
                    error.response = JSON.parse(status.response)
                    break;
                case 403: error.message = '拒绝访问(403)';
                    error.response = JSON.parse(status.response)
                    break;
                case 404: error.message = '请求出错(404)';
                    error.response = JSON.parse(status.response)
                    break;
                case 408: error.message = '请求超时(408)';
                    error.response = JSON.parse(status.response)
                    break;
                case 500: error.message = '服务器错误(500)';
                    error.response = JSON.parse(status.response)
                    // router.push('/500')
                    break;
                case 501: error.message = '服务未实现(501)';
                    error.response = JSON.parse(status.response)
                    break;
                case 502: error.message = '网络错误(502)';
                    error.response = JSON.parse(status.response)
                    break;
                case 503: error.message = '服务不可用(503)';
                    error.response = JSON.parse(status.response)
                    break;
                case 504: error.message = '网络超时(504)';
                    error.response = JSON.parse(status.response)
                    break;
                case 505: error.message = 'HTTP版本不受支持(505)';
                    error.response = JSON.parse(status.response)
                    break;
                default: error.message = `连接出错(${error.response.status})!`;
            }
        } else {
            error.message = '连接服务器失败!'
        }

        return Promise.reject(error)
    })

    return instance(config)
}