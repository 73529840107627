import {request} from './request'

export function getBanner(){
    return request({
        method:"get",
        url:"/"
    })
}

export function getHome(){
    return request({
        method:"get",
        url:"/website/homePage"
    })
}
//企业荣誉
export function getHonor(){
    return request({
        method:"get",
        url:"/website/honor"
    })
}

//合作伙伴
export function getPartner(){
    return request({
        method:"get",
        url:"/website/partner"
    })
}
//商城 入门
export function getImg(){
    return request({
        method:"get",
        url:"/website/elsePage",
    })
}
//发起合伙定制
export function setInitiate(name,phone,req){
    return request({
        method:"post",
        url:"/website/investWanted",
        data:{
            name,phone,req
        }
    })
}
//发起合伙定制
export function getInitiate(){
    return request({
        method:"get",
        url:"/website/investWanted",
    })
}
//公告
export function getNotice(page,limit){
    return request({
        url:'/website/msg',
        params:{
            page,limit
        }
    })
}
export function getNoticeDetail(id){
    return request({
        url:`/website/msg/${id}`,
    })
}
//友情链接
export function getLinks(){
    return request({
        url:'/website/links'
    })
}

// 下载app
export function getDownload(){
    return request({
        url:'/website/download'
    })
}